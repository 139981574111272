<template>
  <div id="landingpage">
    <GrapesJSRender :components="data.landingPage.elements" v-if="typeof data.landingPage !== 'undefined'"/>
  </div>
</template>
<script>

import GrapesJSRender from '@/components/GrapesJs/GrapesJSRender'

export default {
  data(){
    return {
      data: {
        landingPage: undefined,
        recipient: undefined
      }
    }
  },
  components: {
    GrapesJSRender
  },
  async beforeCreate() {
    await this.$store.getPublicLandingpage(
        this.$route.params.id,
        this.$route.params.recipient,
        this.$route.params.code,
    )
    this.data = Object.assign({}, this.$store.state.landingpage)

    window.$eventId = this.$store.state.landingpage.landingPage.eventId;

    document.title = this.data.landingPage.title
  },
  computed: {
  }
}
</script>
<style>
@import "/css/landingpage.css";
</style>
